import React from 'react';
import { graphql } from 'gatsby';
import { Box, Text, Container, SimpleGrid } from '@chakra-ui/react';
import { BooksLeadInHeader } from 'timelesstime-ui/components/lead-in-header';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import Heading from 'timelesstime-ui/components/heading';
import SEO from 'timelesstime-ui/components/seo';
import OrganizationStructuredData from 'timelesstime-ui/components/json-ld';
import PageLayout from '../components/layout';
import BookCard from '../components/book-card';

const BooksPage = ({
  data: {
    jsonLdFeaturedImage,
    booksPage: { title, heading, metaKeywords, metaDescription, splashImage },
    books: { nodes: books },
  },
}) => (
  <PageLayout
    leadInHeader={
      <BooksLeadInHeader
        heading={heading || title}
        crumbs={[
          {
            url: '/tools/',
            title: title,
          },
        ]}
      />
    }
  >
    <SEO
      title={title}
      keywords={metaKeywords}
      description={metaDescription}
      canonicalPath="/"
      thumbnail={jsonLdFeaturedImage}
    />
    <OrganizationStructuredData />

    <Container as="section" maxW="container.lg" mt={6}>
      {/* <SimpleGrid columns={[1, 1, 2]} spacing={[4, 4, 8]}> */}
      {books.map((book) => (
        <BookCard key={book.id} as="article" book={book} />
      ))}
      {/* </SimpleGrid> */}
      {books.length < 1 && (
        <Box>
          <Heading as="h1">Oops, looks like nothing was found.</Heading>
          <Text>
            That doesn&apos;t mean all hope is lost.{' '}
            <CanonicalLink to="/contact/">Get in touch</CanonicalLink>,
            we&apos;d love to hear from you.
          </Text>
        </Box>
      )}
    </Container>
  </PageLayout>
);

export const query = graphql`
  query BooksPageQuery {
    jsonLdFeaturedImage: file(name: { eq: "TimelessTime-FrontPage-Logo" }) {
      childImageSharp {
        gatsbyImageData(width: 400, placeholder: BLURRED, layout: FIXED)
      }
    }
    booksPage: contentfulPage(slug: { eq: "books" }) {
      title
      slug
      heading
      metaDescription
      jsonLdFeaturedImage: featuredImage {
        ...JsonLdFeaturedImage
      }
      jsonLdThumbnailImage: featuredImage {
        ...JsonLdThumbnailImage
      }
      fields {
        path
        url
        writtenAt
        isNew
        keywords
        contentHtml
      }
    }
    books: allContentfulBook(
      sort: { fields: [updatedAt, createdAt], order: DESC }
      filter: { slug: { ne: null } }
    ) {
      nodes {
        title
        slug
        metaDescription
        metaKeywords
        isbn
        heroImage {
          title
          gatsbyImageData(width: 200, placeholder: BLURRED, layout: CONSTRAINED)
        }
        splashImage {
          ...SplashImage
        }
        leadInText
        leadInSecondaryText
        summary {
          raw
        }
        authors {
          id
          firstName
          lastName
          jobTitle
          email
        }
        reviews {
          id
          title
          text {
            text
          }
          reviewLocation
          person
          url
          rating
        }
        fields {
          path
          url
          writtenAt
          isNew
        }
      }
    }
  }
`;

export default BooksPage;
