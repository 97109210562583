import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import Heading from 'timelesstime-ui/components/heading';
import ButtonLink from 'timelesstime-ui/components/button-link';
import RichText from 'timelesstime-ui/components/rich-text';
import BookStructuredData from '../structured-data/book';

const BookCard = ({ book, ...props }) => (
  <Box {...props}>
    <BookStructuredData
      slug={book.slug}
      title={book.title}
      summary={book.summary}
      isbn={book.isbn}
      reviews={book.reviews}
      authors={book.authors}
    />
    <Box>
      <Box as="header">
        <Heading as="h1" mt={0} mb={1}>
          <CanonicalLink to={book.fields.path}>{book.title}</CanonicalLink>
        </Heading>
      </Box>
      <RichText content={book.summary} />
    </Box>
    <Flex justify="center">
      <ButtonLink
        to={book.fields.path}
        colorScheme="orange"
        color="white"
        mt={4}
      >
        Read about {book.title}
      </ButtonLink>
    </Flex>
  </Box>
);

export default BookCard;
