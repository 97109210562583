import { useStaticQuery, graphql } from 'gatsby';

const useLeadInImages = () => {
  const images = useStaticQuery(graphql`
    query useLeadInImages {
      leadInMan: file(name: { eq: "topbar-man-front" }) {
        ...LeadInImage
      }
      leadInWoman: file(name: { eq: "topbar-woman-front" }) {
        ...LeadInImage
      }
      leadInGroup: file(name: { eq: "topbar-group" }) {
        ...LeadInImage
      }
      leadInTech: file(name: { eq: "topbar-tech" }) {
        ...LeadInImage
      }
    }
  `);
  return images;
};

export default useLeadInImages;
