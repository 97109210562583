import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import { Box, Container, Flex } from '@chakra-ui/react';
import useLeadInImages from 'timelesstime-gatsby-utils/hooks/useLeadInImages';
import BgImage from '../bg-image';

import LeadInSiteSearch from './lead-in-site-search';
import CallMeBack from '../call-me-back';
import BreadCrumbs from '../breadcrumbs';
import Heading from '../heading';

const getBackgroundImage = (
  imageType,
  { leadInMan, leadInWoman, leadInGroup, leadInTech },
) => {
  switch (imageType) {
    case 'woman':
      return getImage(leadInWoman);
    case 'group':
      return getImage(leadInGroup);
    case 'tech':
      return getImage(leadInTech);
    case 'man':
    default:
      return getImage(leadInMan);
  }
};

const LeadInHeader = ({
  imageType,
  heading,
  showSearch,
  showCallMeBack,
  crumbs,
}) => {
  const images = useLeadInImages();
  return (
    <BgImage
      as="header"
      bg={getBackgroundImage(imageType, images)}
      preload
      adaptiveLoading
    >
      <Container maxW="container.lg" py={4} px={2}>
        <Flex
          justifyContent="space-between"
          height="35px"
          alignContent="flex-start"
        >
          <BreadCrumbs crumbs={crumbs} />
          {showSearch && (
            <Box display={['none', 'none', 'block']}>
              <LeadInSiteSearch />
            </Box>
          )}
        </Flex>
        <Heading
          as="h1"
          fontSize="3xl"
          mt={0}
          mb={4}
          color="white"
          maxW="calc(100% - 150px)"
        >
          {heading}
        </Heading>
        {showCallMeBack && (
          <Flex justifyContent="flex-end" display={['none', 'none', 'flex']}>
            <Box maxW="150px" marginTop="-32px">
              <CallMeBack />
            </Box>
          </Flex>
        )}
      </Container>
    </BgImage>
  );
};
LeadInHeader.defaultProps = {
  imageType: 'man',
  showSearch: true,
  showCallMeBack: true,
  crumbs: [],
};
LeadInHeader.propTypes = {
  imageType: PropTypes.oneOf(['man', 'woman', 'group', 'tech']),
  heading: PropTypes.string.isRequired,
  showSearch: PropTypes.bool,
  showCallMeBack: PropTypes.bool,
  crumbs: BreadCrumbs.propTypes.crumbs,
};

export default LeadInHeader;
