import React from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react';

import CanonicalLink from '../canonical-link';
import { Breadcrumbs as BreadcrumbStructuredData } from '../json-ld';

const crumbsWithHome = (crumbs) => [
  {
    url: '/',
    title: 'Home',
  },
  ...crumbs,
];

const Breadcrumbs = ({ crumbs, ...props }) => (
  <Breadcrumb color="white" fontSize="sm" flexGrow="1" {...props}>
    <BreadcrumbStructuredData crumbs={crumbsWithHome(crumbs)} />
    {crumbsWithHome(crumbs).map((crumb) => (
      <BreadcrumbItem
        key={crumb.url}
        maxWidth="50%"
        sx={{
          '&:last-child a': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
        }}
      >
        <BreadcrumbLink as={CanonicalLink} to={crumb.url} {...props}>
          {crumb.title}
        </BreadcrumbLink>
      </BreadcrumbItem>
    ))}
  </Breadcrumb>
);

Breadcrumbs.defaultProps = {
  crumbs: [],
};

Breadcrumbs.propTypes = {
  crumbs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ),
};

export default Breadcrumbs;
