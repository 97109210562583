/* eslint-disable react/require-default-props */
import React from 'react';
import LeadInHeader from './lead-in-header';

export { default } from './lead-in-header';

export const CaseStudyLeadInHeader = (props) => (
  <LeadInHeader imageType="woman" {...props} />
);
CaseStudyLeadInHeader.propTypes = {
  heading: LeadInHeader.propTypes.heading,
  crumbs: LeadInHeader.propTypes.crumbs,
};

export const KnowledgebaseLeadInHeader = (props) => (
  <LeadInHeader imageType="group" {...props} />
);
KnowledgebaseLeadInHeader.propTypes = {
  heading: LeadInHeader.propTypes.heading,
  crumbs: LeadInHeader.propTypes.crumbs,
};

export const EventLeadInHeader = (props) => (
  <LeadInHeader imageType="tech" {...props} />
);
EventLeadInHeader.propTypes = {
  heading: LeadInHeader.propTypes.heading,
  crumbs: LeadInHeader.propTypes.crumbs,
};

export const ServiceLeadInHeader = (props) => (
  <LeadInHeader imageType="man" {...props} />
);
ServiceLeadInHeader.propTypes = {
  heading: LeadInHeader.propTypes.heading,
  crumbs: LeadInHeader.propTypes.crumbs,
};

export const ToolLeadInHeader = (props) => (
  <LeadInHeader imageType="tech" {...props} />
);
ToolLeadInHeader.propTypes = {
  heading: LeadInHeader.propTypes.heading,
  crumbs: LeadInHeader.propTypes.crumbs,
};

export const BooksLeadInHeader = (props) => (
  <LeadInHeader imageType="group" {...props} />
);
BooksLeadInHeader.propTypes = {
  heading: LeadInHeader.propTypes.heading,
  crumbs: LeadInHeader.propTypes.crumbs,
};
