import React, { useState, useRef } from 'react';
import { navigate } from 'gatsby';
import { Input, Flex, IconButton } from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';

const navigateToSearchPage = (searchQuery) => {
  navigate(`/search?query=${searchQuery}`);
};

const LeadInSiteSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const inputRef = useRef();
  return (
    <Flex color="white" fontSize="xs" alignItems="center">
      <Input
        variant="unstyled"
        size="sm"
        width="175px"
        ref={inputRef}
        type="search"
        aria-label="Site Search"
        placeholder="search our knowledgebase"
        border="1px solid"
        borderColor="transparent"
        paddingLeft={2}
        paddingRight={2}
        py={1}
        _placeholder={{
          fontSize: 'xs',
          color: 'white',
          textAlign: 'right',
        }}
        _focus={{
          borderColor: 'white',
          width: '225px',
        }}
        _hover={{
          borderColor: 'white',
          width: '225px',
        }}
        onChange={(event) => setSearchQuery(event.target.value)}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            navigateToSearchPage(searchQuery);
          }
        }}
      />
      <IconButton
        variant="unstyled"
        size="sm"
        display="flex"
        fontSize="md"
        aria-label="Search"
        onClick={(event) => {
          event.preventDefault();
          if (searchQuery === '') {
            inputRef.current.focus();
          } else {
            navigateToSearchPage(searchQuery);
          }
        }}
      >
        <FaSearch />
      </IconButton>
    </Flex>
  );
};

export default LeadInSiteSearch;
