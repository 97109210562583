import React from 'react';
import PropTypes from 'prop-types';

import JsonLd, { organizationId } from 'timelesstime-ui/components/json-ld';

export const type = () => 'Book';
export const id = (slug) =>
  `https://timelesstime.co.uk/books/${slug}#${type()}/`;

const orgId = organizationId();

const BookStructuredData = ({
  slug,
  title,
  summary,
  isbn,
  reviews,
  authors,
}) => (
  <JsonLd
    data={{
      '@context': 'http://schema.org',
      '@type': type(),
      '@id': id(slug),
      name: title,
      description: summary,
      isFamilyFriendly: 'true',
      isbn,
      copyrightHolder: {
        '@id': orgId,
      },
      author: authors.map((author) => ({
        '@type': 'Person',
        name: `${author.firstName} ${author.lastName}`,
        givenName: author.firstName,
        familyName: author.lastName,
        jobTitle: author.jobTitle,
        email: author.email,
      })),
      review: reviews.map((review) => ({
        '@type': 'Review',
        name: review.title,
        reviewBody: review.text,
        reviewRating: {
          '@type': 'Rating',
          ratingValue: review.rating,
        },
        author: {
          '@type': 'Person',
          name: review.person,
        },
        reviewBody: review.text.text,
        url: review.url,
      })),
      ...((reviews) => {
        if (reviews.length < 1) {
          return {};
        }
        debugger;
        const avgRating =
          reviews.reduce((aggRating, { rating }) => aggRating + rating, 0) /
          reviews.length;
        const roundedRating = Math.ceil(avgRating / 50) * 5;
        return {
          aggregateRating: {
            '@type': 'AggregateRating',
            ratingValue: roundedRating,
            reviewCount: reviews.length,
          },
        };
      })(reviews),
      inLanguage: {
        '@type': 'Language',
        name: 'English',
        alternateName: 'en',
      },
    }}
  />
);

BookStructuredData.defaultProps = {
  createdAt: undefined,
  updatedAt: undefined,
};

BookStructuredData.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  plaintextContent: PropTypes.string.isRequired,
  isFree: PropTypes.bool.isRequired,
  createdAt: PropTypes.instanceOf(Date),
  updatedAt: PropTypes.instanceOf(Date),
};

export default BookStructuredData;
